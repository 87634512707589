:root {
  --primary: #242424;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #242424;
  padding: 8px 24px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--xlarge {
  padding: 12px 26px;
  font-size: 72px;
}

.btn--large:hover,
.btn--medium:hover, 
.btn--xlarge:hover {
  transition: all 0.3s ease-out;
  background: #242424;
  color: #fff;
  transition: 250ms;
}

/* a {
  text-decoration: none;
} 

a:link {color: black;}
a:visited {color: black;} 
a:hover {color: black;}     
a:active {color: black;} */

.aClass {
  color: #242424;
  text-decoration: none;
}