* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.h111 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 100%;
}

.h222 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 100%;
}

.centered {
  position: absolute;
  top: 90%;
  left: 50%;
  font-size: 60px;
  transform: translate(-50%, -50%);
}

@font-face {
  font-family: "Funkytown";
  src: local("Funkytown"),
   url("./fonts/Funkytown.ttf") format("truetype");
  }

  @font-face {
    font-family: "FunkyVibes";
    src: local("FunkyVibes"),
     url("./fonts/FunkyVibes.ttf") format("truetype");
    }
