.wrapper {
  background: linear-gradient(
    180deg,
    #bbd7f6 0%,
    #bbd7f6 100%
  );
    height: auto;
    min-height: 100vh;
}

.teamLogo {
  position: relative;
  display: flex;
  padding-top: 100px;
  width: 60%;
  height: 60vh;
  margin: 0 auto;
  max-width: 1100px;
  min-height: 280px;
}

.teamLogoMobile {
  position: relative;
  display: flex;
  padding-top: 120px;
  width: 90%;
  height: 50vh;
  margin: 0 auto;
  max-height: 300px;
  min-height: 280px;
}

.containerTeam {
    display: flex;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
    justify-content: center;
}

.containerMember {
    justify-content: center;
    align-content: center;
    align-self: flex-start;
    display: flex;
    text-align: center;
    min-width: 300px;
    width: 200px;
    margin: 2rem;
    font-size: 30px;
    flex-direction: column;
  }

  .font-face-vibes {
    font-family: "FunkyVibes";
   }

  .teamImage {
    max-height: 300px;
  }

  @media screen and (max-width:960px) {
    .teamLogo {
      position: relative;
      display: flex;
      padding-top: 120px;
      width: 90%;
      height: 50vh;
      margin: 0 auto;
      max-height: 300px;
      min-height: 280px;
    }
  }
