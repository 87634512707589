.wrapperMint {
  /* background: linear-gradient(
    180deg,
    #bbd7f6 0%,
    #bbd7f6 100%
  ); */
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.backgroundImgMint {
  position: fixed;
  height: 100vh;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  min-height: 600px;
  min-width: 360px;
}

.mintLogo {
  position: relative;
  /* display: flex; */
  padding-top: 100px;
  width: 60%;
  height: 40vh;
  margin: 0 auto;
  max-width: 700px;
  min-height: 280px;
}

/* .mintContainer */

.mintContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  /* padding-top: 100px; */
  /* width: 50%; */
  /* height: 80vh; */
  /* margin: 0 auto; */
  /* margin: 2rem; */
  max-width: 1100px;
  align-items: center;
  font-family: "font-face-helv";
}

.header-mint {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  margin: 5rem;
}

.header-container {
  display: flex;
  align-items: center;

}

.mintMobile {
  position: relative;
  display: flex;
  width: 80%;
  height: 100vh;
  padding-top: 100px;
  margin: 0 auto;
  max-height: 300px;
}

.counter {
  border-radius: 45px;
  border: 2px solid #ffffff;
  padding: 12px;
  color: #fff;
  font-family: "font-face-helv";
  font-size: 58px;
  /* width: 200px; */
  align-items: center;
}

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 24px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
    margin-bottom: 2rem;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .hero-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem;
  }
  .hero-btns2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 1rem; */
  }

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  pointer-events: none;
}

@media screen and (max-width:960px) {
  .mintLogo {
    position: relative;
    display: flex;
    padding-top: 100px;
    width: 50%;
    height: 30vh;
    margin: 0 auto;
    max-height: 300px;
    min-height: 280px;
  }

  .counter {
    border-radius: 45px;
    border: 2px solid #ffffff;
    padding: 12px;
    color: #fff;
    font-family: "font-face-helv";
    font-size: 24px;
    /* width: 200px; */
    align-items: center;
  }

  .header-mint {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    margin: 2rem;
  }
}

.font-face-helv {
  font-family: "Helvetica";
 }


