.container2 {
  background: linear-gradient(
    180deg,
    #bbd7f6 0%,
    #bbd7f6 100%
  );
    min-width: 360px;
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
}

.backgroundImg {
  position: fixed;
  height: 100vh;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  min-height: 600px;
  min-width: 360px;
}

.container3 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 80%;
  scroll-snap-align: center;
  margin: 0 auto;
  font-size: 54px;
  max-width: 1500px;
  flex-direction: column;
}

.container3Mobile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 90%;
  scroll-snap-align: center;
  margin: 0 auto;
  font-size: 24px;
  flex-direction: column;
}

.missionHeader {
  width: 80%;
  margin-top: -150px;
}

.mintMissionPage{
  margin-top: 50px;
}

  .ourMission {
    position: relative;
    display: flex;
    width: 60%;
    height: 50vh;
    margin: 0 auto;
    max-width: 1100px;
  }

  .ourMissionMobile {
    position: relative;
    display: flex;
    width: 80%;
    height: 50vh;
    margin: 0 auto;
    max-height: 300px;
  }

  .font-face-gm {
    font-family: "Funkytown";
   }

   .font-face-vibes {
    font-family: "FunkyVibes";
   }

   .font-face-helv {

    font-family: "Helvetica";
   }

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%
}

.icon-scroll{
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 90%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

.icon-scroll:before{
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

@media screen and (max-height: 960px) {
  .missionHeader {
    width: 100%;
    margin-top: -150px;
  }
  
  .mintMissionPage{
    margin-top: 20px;
  }

  .ourMissionMobile {
    position: relative;
    display: flex;
    width: 40%;
    height: 40vh;
    margin: 0 auto;
    max-height: 300px;
    max-width: 600px;
  }

  .container3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 80%;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 24px;
    max-width: 1500px;
    flex-direction: column;
  }
  .container3Mobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 90%;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 24px;
    flex-direction: column;
  }

  .elfLeft {
    position: absolute;
    height:25vh;
    left: 0px;
    bottom: 0px;
    max-height: 860px;
  }
  
  .elfRight {
    position: absolute;
    height:25vh;
    right: 0px;
    bottom: 0px;
    max-height: 860px;
    transform: rotateY(180deg);
  }

}

@media screen and (max-width: 960px) {
  .ourMissionMobile {
    position: relative;
    display: flex;
    width: 40%;
    height: 40vh;
    margin: 0 auto;
    max-height: 300px;
  }

  .container3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 80%;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 24px;
    max-width: 1500px;
    flex-direction: column;
  }
  .container3Mobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 90%;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 24px;
    flex-direction: column;
  }

  .elfLeft {
    position: absolute;
    height:25vh;
    left: 0px;
    bottom: 20px;
    max-height: 860px;
  }
  
  .elfRight {
    position: absolute;
    height:25vh;
    right: 0px;
    bottom: 20px;
    max-height: 860px;
    transform: rotateY(180deg);
  }

}

@media screen and (min-width: 360px) and (max-width: 960px) {
  .backgroundImg {
    position: fixed;
    height: 100vh;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
  }

  .ourMissionMobile {
    position: relative;
    display: flex;
    width: 60%;
    height: 40vh;
    margin: 0 auto;
    max-height: 300px;
  }

  .container3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 80%;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 24px;
    max-width: 1500px;
    flex-direction: column;
  }
  .container3Mobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 90%;
    scroll-snap-align: center;
    /* margin: 0 auto; */
    font-size: 24px;
    flex-direction: column;
  }

  .elfLeft {
    position: absolute;
    height:25vh;
    left: 0px;
    bottom: 0px;
    max-height: 860px;
  }
  
  .elfRight {
    position: absolute;
    height:25vh;
    right: 0px;
    bottom: 0px;
    max-height: 860px;
    transform: rotateY(180deg);
  }
}

@media screen and (min-height: 960px) and (max-width: 500px) {
  .elfLeft {
    position: absolute;
    height:25vh;
    left: 0px;
    bottom: 0px;
    max-height: 860px;
  }
  
  .elfRight {
    position: absolute;
    height:25vh;
    right: 0px;
    bottom: 0px;
    max-height: 860px;
    transform: rotateY(180deg);
  }
}

.elfLeft {
  position: absolute;
  height:30vh;
  left: 0px;
  bottom: 20px;
  max-height: 860px;
}

.elfRight {
  position: absolute;
  height:30vh;
  right: 0px;
  bottom: 20px;
  max-height: 860px;
  transform: rotateY(180deg);
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}



