.containerRoadmap {
  background: linear-gradient(
    180deg,
    #bbd7f6 0%,
    #bbd7f6 100%
  );
  position: relative;
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.containerRoadmapMobile {
  background: linear-gradient(
    180deg,
    #bbd7f6 0%,
    #bbd7f6 100%
  );
  position: relative;
  height: 100vh;
  /* width: 100%; */
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.backgroundRoadmap {
  position: absolute;
  height: 600vh;
  width: 100%;
  left: 0;
  right: 0;


  max-width: 1900px;
  /* min-width: 1500px; */
  margin: 0 auto;

}



.containerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  margin: 0 auto;
  font-size: 84px;
  max-width: 1900px;
  /* min-width: 1500px; */
  flex-direction: column;

}

.containerHeaderMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40vh;
  width: 100%;
  /* padding-left: 40px; */
  padding-top: 100px;
  scroll-snap-align: center;
  margin: 0 auto;
  font-size: 84px;
  min-width: 360px;
  max-width: 600px;
  /* max-width: 600px; */
  flex-direction: column;
  /* min-height: 1000px; */
}

.containerPresent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 80%;
  scroll-snap-align: center;
  margin: 0 auto;
  /* font-size: 84px; */
  max-width: 1500px;
  /* min-width: 1500px; */
  flex-direction: column;
}


/* .containerPresentMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 28vh;
  width: 100%;
  scroll-snap-align: center;
  margin: 0 auto;
  font-size: 36px;
  min-width: 360px;
  max-width: 600px;

} */
  
@media screen and (max-width: 960px) {
  .containerPresentMobile {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 28vh;
    width: 100%;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 36px;
    min-width: 360px;
    /* flex-direction: column; */
    /* transform: translate(100px, 500px); */
  }

  .backgroundRoadmapMobile {
    position: absolute;
    height: 180vh;
    width:100%;
    min-width: 360px;
    left: 0;
    right: 0;
    margin: 0 auto;
    min-height: 1000px;
  
  }

  .containerHeaderMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40vh;
    width: 100%;
    padding-top: 100px;
    scroll-snap-align: center;
    margin: 0 auto;
    font-size: 84px;
    min-width: 360px;
    flex-direction: column;
  }
}

.present10 {
  position: relative;
  display: flex;
  width: 40%;
  height: 40vh;
  margin: 0 auto;
}

.presentText {
  position: relative;
  /* justify-content: center; */
  align-items: center;
  display: flex;
  width: 60%;
  height: 20vh;
  margin: 0 auto;
}

.present10Mobile {
  position: relative;
  display: flex;
  width: 50%;
  height: 20vh;
  margin: 0 auto;
}

.headerMobile {
  position: relative;
  display: flex;
  width: 60%;
  height: 20vh;
  margin: 0 auto;
}

/* ::-webkit-scrollbar {
  display: none;
} */
